import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import * as styles from "./zapravkaVRassrochku.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Заказать карту"

type NewYearProps = {
  orderNum?: string
}

export default function NewYear({ orderNum }: NewYearProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <div className={styles.text}>
          <h1>Как заправляться сейчас, а платить потом?</h1>
          <p>Рассрочка всегда 0%</p>
        </div>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
